






import { AsyncComponent } from 'vue'
import { Component } from 'vue-property-decorator'

import { logger } from '../../../support'

import { AbstractModuleUi } from '../../abstract'

import { NavbarModule } from '../Navbar.contracts'
import { NavbarModuleVersion, navbarModuleVersionComponentRegistry } from '../Navbar.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'NavbarModuleUi'
})
export class NavbarModuleUi extends AbstractModuleUi<NavbarModule> {
  protected versionEnum = NavbarModuleVersion
  /**
   * Render version component
   */
  public get component (): AsyncComponent | undefined {
    const component = navbarModuleVersionComponentRegistry[this.navbarVersion]

    if (typeof component === 'undefined') {
      logger(
        `NavbarModule.ui.component(): There's no Vue component associated with the [${this.data.version}] NavbarModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Set default version of Navbar
   */
  public get navbarVersion () {
    if (!this.data.version) {
      return NavbarModuleVersion.Default
    }

    return this.data.version
  }
}

export default NavbarModuleUi
