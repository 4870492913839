






















import { Component, Mixins } from 'vue-property-decorator'

import { collapse } from '../../../support/directives'

import { AbstractModuleUi } from '../../abstract'
import { AllowedHAlignment } from '../../../contexts'

import { UseAlignment } from '../../mixins/UseAlignment'
import { UseColor } from '../../mixins/UseColor'
import { UiMarkdown } from '../../partials/UiMarkdown'

import { TextModule } from '../Text.contracts'

/**
 * Presentational component for the `TextModuleUi`.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<TextModuleUi>({
  name: 'TextModuleUi',
  directives: { collapse },
  components: { UiMarkdown }
})
export class TextModuleUi extends Mixins<AbstractModuleUi<TextModule>,
  UseAlignment, UseColor>(AbstractModuleUi, UseAlignment, UseColor) {
  /**
   * Determines whether the extended content part is collapsed at a given moment.
   *
   * @see extendedContent
   */
  public isExtendedContentCollapsed: boolean = true

  protected align = this.data.content.align ?? AllowedHAlignment.Center
  protected alignDesktop = this.data.content.alignDesktop
  protected color = this.data.content.color

  /**
   * Determines whether the component has been provided with the correct `content` prop.
   */
  public get hasContent (): boolean {
    return typeof this.data.content.content === 'string' && this.data.content.content.length > 0
  }

  /**
   * Determines whether module has customClasses
   */
  public get customClass (): Array<string> {
    if (!this.data.content.customClass || this.data.content.customClass.length === 0) {
      return []
    }
    return this.data.content.customClass
  }

  /**
   * Determines whether the component has been provided with the correct `extendedContent` prop.
   */
  public get hasExtendedContent (): boolean {
    return typeof this.data.content.extendedContent === 'string' &&
      this.data.content.extendedContent.length > 0
  }

  public get togglerTranslation (): string {
    return this.isExtendedContentCollapsed ? 'readMore' : 'collapse'
  }

  /**
   * Handles the `@click` event on the extended content's toggler button.
   */
  public onExtendedContentTogglerClick (): void {
    this.revealExtendedContent()
  }

  /**
   * Reveals the extended content part.
   */
  private revealExtendedContent (): void {
    this.isExtendedContentCollapsed = !this.isExtendedContentCollapsed
  }

  protected get verticalAlignmentClass (): string {
    return this.style.alignSelf === 'center' ? '--vertical-alignment-center' : ''
  }
}

export default TextModuleUi
