





















import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import VClamp from 'vue-clamp'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { ProductSpecification } from '../../../../../contexts'
import { Theme } from '../../../../../dsl/atoms/Link/Link.config'

import { baseToggleConfig } from './InciSection.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<InciSection>({
  name: 'InciSection',
  components: {
    VClamp
  },
  mounted () {
    const limit = this.isMobile() ? this.baseToggleConfig.mobileChars : this.baseToggleConfig.desktopChars
    if (this.inci.length < limit) {
      this.isExpandButtonVisible = false
    }
  }
})
export class InciSection extends Vue {
  public readonly Theme = Theme
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ required: true, type: String })
  public inci!: ProductSpecification['inci']

  public readonly baseToggleConfig = baseToggleConfig

  public isExpandButtonVisible: boolean = true

  public isInciExpanded: boolean = false

  public toggleInciBtn (): void {
    this.isInciExpanded = !this.isInciExpanded
  }
}

export default InciSection
