





























import { Component, Prop, PropSync } from 'vue-property-decorator'
import { SubscribeConfig } from '@kluseg/trackify/dist/main'

import { AbstractModuleUi } from '../../../abstract'

import {
  NewsletterFormState,
  NewsletterModule
} from '../../Newsletter.contracts'
import { NewsletterModuleConfig } from '../../Newsletter.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'Default'
})
export class Default extends AbstractModuleUi<NewsletterModule> {
  @PropSync('configuration', { type: Object, required: true })
  public readonly _config!: NewsletterModuleConfig

  @Prop({ type: Boolean, required: true })
  public readonly isLoading!: boolean

  @Prop({ type: Function, required: true })
  public readonly clearState!: boolean

  @Prop({ type: Function, required: true })
  public readonly subscribe!: boolean

  @Prop({ type: Object, required: true })
  public readonly formData!: SubscribeConfig

  @Prop({ type: String, required: false, default: null })
  public readonly state?: NewsletterFormState

  @Prop({ type: String, required: true })
  public readonly theme!: string

  @Prop({ type: String, required: true })
  public readonly message!: string
}

export default Default
