

































import {
  AnyObject,
  Authentication,
  AuthServiceType,
  IModal,
  ModalType
} from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract'
import { ProductReviewData, Variant } from '../../../contexts'

import { Inject, logger } from '../../../support'
import {
  IProductReviewsRepository,
  ReviewsRepositoryType
} from '../../../front/products/contracts/repositories'
import { Loader } from '../../../front/shared/molecules/Loader'
import { Modals } from '../../../front/products/config/modals'
import { PaginationProps } from '../../../dsl/molecules/Pagination'

import ProductReview from '../../../front/products/molecules/ProductReview/ProductReview.vue'
import RateSummary from '../../../front/products/molecules/RateSummary/RateSummary.vue'

import { getPaginationConfig } from '../../ProductReviews/ProductReviews.helpers'
import { ProductReviewsID } from '../../ProductReviews/ProductReviews.config'
import { ProductCardVariant } from '../../../front/products/organisms/ProductCard/ProductCard.contracts'
import { ProductReviewsModule } from '../ProductReviews.contracts'
import { translateProductToProductCard } from '../../../front/products/organisms/ProductCard/ProductCard.helpers'
import { openAuthDrawer, UserModel } from '../../../front/auth/shared'
import { DrawerType, IDrawer } from '../../../front/shared/contracts/services'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<ProductReviewsModuleUi>({
  name: 'ProductReviewsModuleUi',
  components: { Loader, ProductReview, RateSummary },
  created (): void {
    if (this.data.content.product) {
      this.setActiveVariant(Object.keys(this.data.content.product.variants)[0])
      this.fetchProductReviewsList()
    }
  }
})
export class ProductReviewsModuleUi extends AbstractModuleUi<ProductReviewsModule> {
  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(ReviewsRepositoryType, false)
  protected readonly reviewsRepository?: IProductReviewsRepository

  /**
   * Determines variant which is currently displayed.
   */
  public activeVariant: ProductCardVariant | undefined | null = null

  /**
   * Articles list current page.
   */
  public currentPage: number = 1

  /**
   * Are reviews being fetched
   */
  public isLoading: boolean = false

  /**
   * Array with reviews objects
   */
  public reviews: ProductReviewData[] | null = null

  public authCheck (): boolean {
    return this.authService?.check() || false
  }

  /**
   * Fetch product reviews list
   * @protected
   */
  protected async fetchProductReviewsList (): Promise<void> {
    if (!this.reviewsRepository || !this.activeVariant || !this.product) {
      return
    }

    const skus = this.variants.map((variant) => {
      return variant.sku
    })

    try {
      this.isLoading = true
      this.reviews = await this.reviewsRepository.loadProductReviews(skus)
    } catch (e) {
      logger((e as Error).message, 'error')
    }

    this.isLoading = false
  }

  /**
   * Determines is list empty.
   */
  public get isEmptyList (): boolean {
    return this.totalReviews === 0
  }

  /**
   * Articles list pagination config.
   */
  public get paginationConfig (): Omit<PaginationProps, 'currentPage'> {
    return getPaginationConfig(this.totalReviews, this.reviewsPerPage)
  }

  /**
   * Determines product related with reviews
   */
  public get product () {
    return this.content.product
  }

  public get productReviewsIdentifier () {
    return ProductReviewsID
  }

  /**
   * Determines product rating
   */
  public get rateSummary () {
    if (!this.reviews || !this.product) {
      return null
    }

    // FIXME -> waiting for Mikołaj endpoint
    let validatedReviewsAmount = 0
    const reviewMeanValue = this.variants.map(variant =>
      variant.rating?.average.rate ?? 0)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

    const amountPerRate = [0, 0, 0, 0, 0]

    this.reviews.forEach((review: ProductReviewData) => {
      if (review && review.rate > 0 && review.rate < 6 && Number.isInteger(review.rate)) {
        amountPerRate[review.rate - 1]++
        validatedReviewsAmount++
      }
    })
    return {
      amountPerRate: amountPerRate.reverse(),
      average: {
        amount: validatedReviewsAmount,
        rate: reviewMeanValue
      },
      product: this.product
    }
  }

  /**
   * Reviews list per page
   */
  public get reviewsList () {
    if (!this.reviews || !this.reviews.length) {
      return []
    }

    const start = ((this.currentPage - 1) * this.reviewsPerPage)
    const end = start + this.reviewsPerPage

    return this.reviews.slice(start, end)
  }

  /**
   * Determines amount reviews per page
   */
  public get reviewsPerPage () {
    if (!this.content.reviewsPerPage) {
      return 10
    }

    return this.content.reviewsPerPage
  }

  /**
   * Determines whether product has more than one variant.
   */
  public get hasMoreThanOneVariant (): boolean {
    if (!this.product) {
      return false
    }

    return Array.isArray(this.variants) && Object.keys(this.product.variants).length > 1
  }

  public get selectedVariant (): Variant<string> | undefined {
    if (!this.hasMoreThanOneVariant || !this.$route.query.variant) {
      return Object.values(this.variants)[0]
    }

    return this.product?.variants[String(this.$route.query.variant)]
  }

  public get variantUrlPath (): string | void {
    if (!this.product) {
      return undefined
    }

    if (this.activeVariant && Object.values(this.product.variants).length > 1) {
      return this.activeVariant.link
    }

    return this.product.urlPath || ''
  }

  /**
   * Determines is pagination showed.
   */
  public get showPagination (): boolean {
    return this.content.showPagination
  }

  /**
   * Determines amount of reviews
   */
  public get totalReviews (): number {
    if (!this.reviews || !this.reviews.length) {
      return Number('0')
    }
    return this.reviews.length
  }

  /**
   * Determines product variants
   */
  public get variants (): Variant<string>[] {
    if (!this.product?.variants) {
      return []
    }

    return Object.values(this.product.variants)
  }

  /**
   * Opens add review modal
   */
  public openAddReviewModal (): void {
    if (!this.authCheck() && this.drawerConnector && this.product) {
      return openAuthDrawer(this.drawerConnector)
    }

    if (!this.product) {
      return
    }

    const variantWithColor = this.product.variantSelector?.color?.find(({ slug }) => {
      if (!this.selectedVariant) {
        return
      }

      return slug === this.selectedVariant.identifier.color
    })

    this.modalConnector.open(Modals.AddReviewModal, {
      sku: this.$route.query.sku ?? this.activeVariant?.sku,
      title: this.selectedVariant ? this.selectedVariant.name : this.activeVariant?.name,
      description: '',
      variantHex: variantWithColor?.value ?? ''
    })
  }

  /**
   * Sets the active variant.
   * @param sku - sku of the product.
   */
  public setActiveVariant (slug: string): void {
    if (this.product) {
      this.activeVariant = translateProductToProductCard(this.product).variants[slug]
    }
  }

  public pickReviewByVariantSku (sku: string): AnyObject {
    const candidate = this.variants.find((variant) => {
      return variant.sku === sku
    })

    if (!candidate) {
      logger(`Could not find [${sku}] variant! `, 'warn')
    }

    return candidate as AnyObject
  }

  /**
   * Builds full url for product with given slug.
   */
  public buildFullProductLink (slug: string): string {
    if (!this.hasMoreThanOneVariant) {
      return this.product?.urlPath ?? ''
    }

    return this.activeVariant?.link ?? ''
  }

  /**
   * Handle query change.
   */
  public updateQuery (newQuery: string): void {
    this.$router.push(newQuery)
  }
}

export default ProductReviewsModuleUi
