















import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract'

import { ArticleCard } from '../../../front/content/organisms/ArticleCard'
import {
  translateContentToArticleCard
} from '../../../front/content/organisms/ArticleCard/ArticleCard.helpers'

import { ArticlesTeaserModule } from '../ArticlesTeaser.contracts'
import { articlesTeaserCarouselConfig } from '../ArticlesTeaser.config'

/**
 * Container component for the `ArticlesTeaserModuleUi`.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ArticlesTeaserModuleUi>({
  name: 'ArticlesTeaserModuleUi',
  components: { ArticleCard }
})
export class ArticlesTeaserModuleUi extends AbstractModuleUi<ArticlesTeaserModule> {
  /**
   * Articles teaser carousel config
   */
  public readonly articlesTeaserCarouselConfig = articlesTeaserCarouselConfig

  /**
   * Articles teaser content
   */
  public get articles () {
    if (!this.content.articles?.length) {
      return
    }

    return this.content.articles.map(translateContentToArticleCard)
  }

  /**
   * Articles teaser heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }

  /**
   * Determines amount of products per carousel page
   */
  public get productsPerPage (): { mobile: number; desktop: number } {
    if (!this.data.content.count) {
      return {
        mobile: 1.5,
        desktop: 3
      }
    }

    return {
      mobile: 1.5,
      desktop: this.data.content.count
    }
  }
}

export default ArticlesTeaserModuleUi
