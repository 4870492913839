






import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../support'

import { AbstractModuleUi } from '../../abstract'

import { NewsletterFormState, NewsletterModule } from '../Newsletter.contracts'
import {
  NEWSLETTER_MODULE_CONFIG_MAP,
  NEWSLETTER_MODULE_KEY,
  NewsletterModuleLayout,
  newsletterModuleVersionsRegistry
} from '../Newsletter.config'
import Default from './versions/Default.vue'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { SubscribeConfig } from '@kluseg/trackify/dist/main'
import { FormErrorsMixin } from '../../../support/mixins'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<NewsletterModuleUi>({
  name: 'Newsletter',
  created () {
    this.config = this.getComponentConfig(
      NEWSLETTER_MODULE_KEY,
      { ...NEWSLETTER_MODULE_CONFIG_MAP }
    )
  }
})
export class NewsletterModuleUi extends AbstractModuleUi<NewsletterModule> {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobileDevice!: () => boolean

  /**
   * Form's payload.
   */
  public formData: SubscribeConfig = {
    email: '',
    name: ''
  }

  /**
   * Determines loading state.
   */
  public isLoading: boolean = false

  /**
   * Determines message of the form.
   */
  public message: string = ''

  /**
   * Determines the state of the form.
   */
  public state: NewsletterFormState | null = null

  /**
   * ee
   */
  public errors: Array<string> = []

  /**
   * Determines component version.
   */
  public get component (): VueConstructor {
    const layout: string = this.getConfigProperty<string>('layout')
    if (!Object.values(NewsletterModuleLayout).includes(layout as NewsletterModuleLayout)) {
      return Default
    }
    return newsletterModuleVersionsRegistry[layout]
  }

  /**
   * Theme of alert.
   */
  public get theme (): string {
    return (this.state === NewsletterFormState.Error || !this.state) ? 'danger' : 'success'
  }

  /**
   * Clears current state.
   */
  public clearState (): void {
    this.state = null
  }

  /**
   * Subscribes to newsletter
   */
  public async subscribe (): Promise<void> {
    try {
      this.isLoading = true

      const privacy = document.querySelector<HTMLInputElement>('#privacy-policy')
      if (privacy!.checked) {
        this.eventBus.emit('app:newsletter.subscribe', this.formData)

        this.state = NewsletterFormState.Success
        this.message = this.$t('modules.Newsletter.form.state.success').toString()
      } else {
        this.state = NewsletterFormState.Error
        this.message = this.$t('modules.Newsletter.form.state.checkCheckbox').toString()
        this.errors.push('email')
      }
    } catch (e) {
      this.state = NewsletterFormState.Error
      this.message = this.$t((e as Error).message).toString()
    } finally {
      this.isLoading = false
    }
  }
}

export default NewsletterModuleUi
