













import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract'

import { UiMarkdown } from '../../partials/UiMarkdown'

import { AccordionModule } from '../Accordion.contracts'

/**
 * Container component for the `AccordionModuleUi`.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */

@Component<AccordionModuleUi>({
  name: 'AccordionModuleUi',
  components: { UiMarkdown }
})
export class AccordionModuleUi extends AbstractModuleUi<AccordionModule> {
  public get shouldRender (): boolean {
    return this.hasContent && !!this.content.items?.length
  }

  /**
   * Accordion heading
   */
  public get heading () {
    if (!this.content.heading) {
      return
    }

    return this.content.heading
  }
}
export default AccordionModuleUi
