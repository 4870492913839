






import { Component, Prop, Vue } from 'vue-property-decorator'

import { TabsModuleContainersRegistry, TabsModuleContent } from '../../../Tabs.contracts'

import { Tablist } from '../Tablist'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<TabsSwitch>({
  name: 'TabsSwitch',
  components: { Tablist },
  mounted (): void {
    if (Object.keys(this.containers).length > 0) {
      Object.values(this.containers).forEach((container) => {
        [...container].forEach((element) => {
          (element as HTMLDivElement).setAttribute('role', 'tabpanel')
        })
      })
    }

    this.activateFirstContainer()
  }
})
export class TabsSwitch extends Vue {
  /**
   * @see TabsModuleUiPresentation.containers
   */
  @Prop({ type: Object, required: true })
  private readonly containers!: TabsModuleContainersRegistry

  /**
   * @see TabsModuleContent.tabs
   */
  @Prop({ type: Array, required: true })
  public readonly tabs!: TabsModuleContent['tabs']

  /**
   * ID of the currently-active (visible) container.
   */
  private mActiveContainerId: string = ''

  /**
   * @see mActiveContainerId
   */
  public get activeContainerId (): TabsSwitch['mActiveContainerId'] {
    return this.mActiveContainerId
  }

  /**
   * @see mActiveContainerId
   */
  public set activeContainerId (containerId: TabsSwitch['mActiveContainerId']) {
    if (containerId === this.activeContainerId) {
      return
    }

    this.hideAllContainers()
    this.showContainerById(containerId)
    this.mActiveContainerId = containerId
  }

  /**
   * Activates the container associated with the 1st tab in the set.
   */
  private activateFirstContainer (): void {
    this.activeContainerId = this.tabs[0].containerId
  }

  /**
   * Hides (conceals) all containers.
   */
  private hideAllContainers (): void {
    Object.values(this.containers).forEach(container => {
      container.forEach((child) => {
        TabsSwitch.hideContainer(child as HTMLDivElement)
      })
    })
  }

  /**
   * Hides (conceals) the passed-in container HTML element.
   *
   * @param container - The container element to hide (conceal).
   */
  private static hideContainer (container: HTMLDivElement): void {
    container.style.display = 'none'
  }

  /**
   * Shows (reveals) the passed-in container HTML element.
   *
   * @param container - The container element to show (reveal).
   */
  private static showContainer (container: HTMLDivElement): void {
    container.setAttribute('tabindex', '0')
    container.style.display = 'block'
  }

  /**
   * Shows (reveals) the container of a passed-in ID.
   *
   * @param containerId - ID of the container to show (reveal).
   */
  private showContainerById (containerId: string): void {
    const containers = this.containers[containerId]
    if (containers.length === 0) {
      return
    }

    containers.forEach((container) => {
      TabsSwitch.showContainer(container as HTMLDivElement)
    })
  }
}

export default TabsSwitch
