






import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract'
import { BenefitsBox } from '../../../front/shared/molecules/BenefitsBox'

import { BenefitsBoxModule } from '../BenefitsBox.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BenefitsBoxModuleUi>({
  name: 'BenefitsBoxModuleUi',
  components: { BenefitsBox }
})
export class BenefitsBoxModuleUi extends AbstractModuleUi<BenefitsBoxModule> {}

export default BenefitsBoxModuleUi
