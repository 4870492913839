








import { Component, Inject } from 'vue-property-decorator'

import { absoluteSlug, appendToSlug, logger, PARENT_CONTENT_DATA_KEY } from '../../../support'
import { BreadcrumbItem } from '../../../dsl/molecules/Breadcrumbs'
import { ContentParent, ContentSneakPeakData } from '../../../contexts'

import { AbstractModuleUi } from '../../abstract'

import { BreadcrumbsModule, ContentBreadcrumbItem } from '../Breadcrumbs.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<BreadcrumbsModuleUi>({
  name: 'Breadcrumbs',
  created () {
    if (typeof this.contentData === 'undefined') {
      logger(
        '[BreadcrumbsModule]: PARENT_CONTENT_DATA_KEY is [undefined]! Unable to resolve the component!',
        'warn'
      )
    }

    this.manageBreadcrumbs()
  }
})
export class BreadcrumbsModuleUi extends AbstractModuleUi<BreadcrumbsModule> {
  @Inject({ from: PARENT_CONTENT_DATA_KEY })
  public readonly contentData!: ContentSneakPeakData

  /**
   * Root parent
   */
  public rootBreadcrumb!: BreadcrumbItem

  /**
   * Array of non-root breadcrumbs elements
   */
  public nonRootBreadcrumbs!: BreadcrumbItem[]

  /**
   * Breadcrumbs items computed getter
   */
  public get breadCrumbsItems (): BreadcrumbItem[] {
    return this.nonRootBreadcrumbs
  }

  /**
   * Set an array of non-root breadcrumbs elements
   */
  public set breadCrumbsItems (items: BreadcrumbItem[]) {
    this.nonRootBreadcrumbs = items
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.breadCrumbsItems.length > 0
  }

  /**
   * Root breadcrumb computed getter
   */
  public get rootItem (): BreadcrumbItem {
    return this.rootBreadcrumb
  }

  /**
   * Set root breadcrumb data
   * @param rootBreadcrumb
   */
  public set rootItem (rootBreadcrumb: BreadcrumbItem) {
    this.rootBreadcrumb = rootBreadcrumb
  }

  /**
   * This method sets root breadcrumbs and all other breadcrumb items.
   * Recurrent method going through ContentParent data
   */
  public manageBreadcrumbs () {
    let recurrentArray = this.getLinksFromContentData(this.contentData.parent)

    let basePath: string = '/'
    recurrentArray = recurrentArray.map(b => {
      basePath = appendToSlug(basePath, b.target)
      b.target = basePath
      return b
    })

    recurrentArray.push({
      label: this.contentData.title,
      target: absoluteSlug(this.contentData.urlPath)
    })

    this.setNonRootBreadcrumbs(recurrentArray)

    this.rootItem = recurrentArray[0]
  }

  /**
   * Set breadcrumbs elements.
   */
  private setNonRootBreadcrumbs (items: BreadcrumbItem[]) {
    this.breadCrumbsItems = items.slice(1)
  }

  /**
   * Recurrent method that forms an array of objects containing 'label' and 'target' keys.
   * These keys are required in UiBreadcrumbs component.
   */
  private getLinksFromContentData (obj?: ContentParent | null): ContentBreadcrumbItem[] {
    if (!obj) {
      return []
    }

    return [...this.getLinksFromContentData(obj.parent), { label: obj.name, target: obj.slug }]
  }
}

export default BreadcrumbsModuleUi
