






import { AsyncComponent } from 'vue'
import { Component } from 'vue-property-decorator'

import { Footer } from '../../../front/shared/organisms/Footer'
import { logger } from '../../../support'

import { AbstractModuleUi } from '../../abstract'

import { FooterModule } from '../Footer.contracts'
import { FooterModuleVersion, footerModuleVersionComponentRegistry } from '../Footer.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<FooterModuleUi>({
  name: 'FooterModuleUi',
  components: { Footer }
})
export class FooterModuleUi extends AbstractModuleUi<FooterModule> {
  protected versionEnum = FooterModuleVersion

  /**
   * Render version component
   */
  public get component (): AsyncComponent | undefined {
    const component = footerModuleVersionComponentRegistry[this.footerVersion]

    if (typeof component === 'undefined') {
      logger(
        `FooterModule.ui.component(): There's no Vue component associated with the [${this.data.version}] FooterModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  /**
   * Set default version of Navbar
   */
  public get footerVersion () {
    if (!this.data.version) {
      return FooterModuleVersion.Default
    }

    return this.data.version
  }

  /**
   * @override
   */
  public get shouldRender (): boolean {
    return this.data && this.data.content && Object.keys(this.data.content).length > 0
  }
}

export default FooterModuleUi
