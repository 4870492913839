


























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType, EventbusType, IEventbus,
  IModal,
  ModalType
} from '@movecloser/front-core'

import { BadgeProps } from '../../../../dsl/atoms/Badge'
import { Inject } from '../../../../support'
import {
  AllowedAttributes,
  AttributeValue,
  ProductData,
  Variant as ProductVariant
} from '../../../../contexts'
import { StarsRateProps } from '../../../../dsl/molecules/StarsRate'
import { ShapeMap, SizeMap, VariantMap } from '../../../../dsl/composables'

import { BenefitsBar } from '../../../shared/molecules/BenefitsBar'
import { Gallery } from '../../../shared/molecules/Gallery'
import { GalleryProps } from '../../../shared/molecules/Gallery/Gallery.contracts'
import { HeartEmptyIcon, HeartFullIcon, InfoIcon } from '../../../shared/icons/icons'
import {
  DrawerType,
  IDrawer,
  IStoreService,
  StoreServiceType
} from '../../../shared/contracts/services'
import {
  translateProductVariantToGalleryProps
} from '../../../shared/molecules/Gallery/Gallery.helpers'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'

import { ProductReviewsID } from '../../../../modules/ProductReviews/ProductReviews.config'

import { openAuthDrawer, UserModel } from '../../../auth/shared'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'

import { Modals } from '../../config/modals'
import {
  translateProductVariantToStarsRateProps
} from '../../helpers/start-rate'
import {
  VariantsSwitch
} from '../../molecules/VariantsSwitch'
import { FavouriteProductsServiceType, IFavouriteProductsService } from '../../contracts/services'

import { isAttribute } from '../ProductCard/ProductCard.helpers'

import { ProductHeaderGratisProps } from './ProductHeaderGratis.contracts'
import AllowedAttributesIcons from './partials/AllowedAttributesIcons.vue'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<ProductHeaderGratis>({
  name: 'ProductHeaderGratis',
  components: {
    AllowedAttributesIcons,
    HeartEmptyIcon,
    HeartFullIcon,
    InfoIcon,
    BenefitsBar,
    Gallery,
    VariantsSwitch
  },
  mounted () {
    this.setVariantOnMount()
    this.checkIsFavoriteVariant()

    this.eventBus.emit('app:product.view', {
      value: this.variant.price.finalPrice,
      items: [
        {
          id: this.variant.sku,
          name: this.variant.name,
          category: this.variant.attributes.mainCategory,
          brand: this.variant.attributes.brand,
          price: 0,
          discount: 0
        }
      ]
    })
  }
})
export class ProductHeaderGratis extends Mixins<ProductCartMixin>(
  ProductCartMixin,
  ToastMixin
) implements ProductHeaderGratisProps {
  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(StoreServiceType, false)
  protected readonly storeService?: IStoreService

  public addToFavoriteBtnLoading: boolean = false

  public addToCartBtnLoading: boolean = false

  public currentVariant: ProductVariant<string> = this.variants[0]

  public isFavorite: boolean | undefined = false

  public ratingAmountDefault: number = 0
  public ratingAvgDefault: number = 0

  public readonly LAST_ITEMS_AMOUNT: number = 3

  public readonly TOOLTIP_DELIVERY_INFO: string = this.$t(
    'front.products.organisms.productHeader.tooltipInfoDelivery').toString()

  public get badges (): BadgeProps[] {
    const badges: BadgeProps[] = []

    // TODO: Note! Should depend on API params
    if (this.isNew && !this.isSale) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isNew').toString(),
        theme: 'default',
        shape: ShapeMap.Rectangle,
        variant: VariantMap.Full,
        size: SizeMap.Medium
      })
    }

    if (this.isSale && !this.isNew) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isSale').toString(),
        theme: 'primary',
        shape: ShapeMap.Rectangle,
        variant: VariantMap.Full,
        size: SizeMap.Medium
      })
    }

    // IsSale has higher priority
    if (this.isSale && this.isNew) {
      badges.push({
        label: this.$t('front.products.organisms.productHeader.attributes.isSale').toString(),
        theme: 'primary',
        shape: ShapeMap.Rectangle,
        variant: VariantMap.Full,
        size: SizeMap.Medium
      })
    }

    return badges
  }

  public get ratingAmount (): number {
    return this.variants.map(variant =>
      variant.rating?.average.amount ?? this.ratingAmountDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get ratingAvg (): number {
    return this.variants.map(variant =>
      variant.rating?.average.rate ?? this.ratingAvgDefault)
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  }

  public get canAddToCart (): boolean {
    return this.variant.isAvailable || this.variant.sellableQuantity > 0
  }

  public get deliveryTimeInfo () {
    if (!this.storeService) {
      return null
    }

    try {
      const info = this.storeService.deliveryInfo
      const day = this.$t(`front.products.organisms.productHeader.infoBarEntryDays.${info.day}`)
      const hours = info.hours

      return { hours, day }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    }
  }

  public get galleryProps (): GalleryProps {
    return translateProductVariantToGalleryProps(this.variant)
  }

  public get hasDiscount (): boolean {
    const { finalPrice, regularPrice } = this.variant.price

    return finalPrice < regularPrice
  }

  public get hasVariant (): boolean {
    return this.variants.length > 1
  }

  public get isFavoriteVariant (): boolean | undefined {
    return this.isFavorite
  }

  private get isNew (): boolean {
    if (typeof this.getAttribute<boolean>(AllowedAttributes.IsNew) === 'undefined') {
      return false
    }

    return Boolean(this.getAttribute<boolean>(AllowedAttributes.IsNew))
  }

  private get isSale (): boolean | undefined {
    if (typeof this.getAttribute<boolean>(AllowedAttributes.IsSale) === 'undefined') {
      return false
    }

    return Boolean(this.getAttribute<boolean>(AllowedAttributes.IsSale))
  }

  public get productLine (): string | undefined {
    return this.getAttribute<string>(AllowedAttributes.ProductLine)
  }

  public get productReviewsIdentifier () {
    return ProductReviewsID
  }

  public get starsRateProps (): Omit<StarsRateProps, 'model'> {
    return translateProductVariantToStarsRateProps(this.variant, false)
  }

  public get variant (): ProductVariant<string> {
    return this.currentVariant
  }

  public get variantLastItems (): boolean {
    return this.variant.sellableQuantity <= this.LAST_ITEMS_AMOUNT
  }

  public get variants (): ProductVariant<string>[] {
    return Object.values(this.product.variants)
  }

  public async onAddToCart (): Promise<void> {
    if (!this.cartService) {
      return
    }

    this.addToCartBtnLoading = true

    try {
      await this.addToCart(this.variant)
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToCartBtnLoading = false
    }
  }

  public async addToFavorite (): Promise<void> {
    if (!this.favouriteProductsService) {
      return
    }

    this.addToFavoriteBtnLoading = true

    try {
      await this.favouriteProductsService.addToFavourite(this.variant.sku)
      this.isFavorite = true
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  public authCheck (): boolean {
    return this.authService?.check() || false
  }

  public async checkIsFavoriteVariant (): Promise<void> {
    if (!this.authCheck() || !this.favouriteProductsService) {
      this.isFavorite = false
      return
    }

    try {
      this.isFavorite = await this.favouriteProductsService.check(this.variant.sku)
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    }
  }

  public generateCategoryLink (categoryTree: AnyObject, name: string): string {
    if (categoryTree.parent) {
      return this.generateCategoryLink(categoryTree.parent, `${categoryTree.slug}/${name}`)
    } else {
      return `/${name.toLowerCase().slice(0, -1)}`
    }
  }

  public async handleFavoriteAction (): Promise<void> {
    if (!this.authCheck() && this.drawerConnector) {
      return openAuthDrawer(this.drawerConnector)
    }

    if (!this.isFavorite) {
      return await this.addToFavorite()
    }

    await this.removeFromFavorite()
  }

  public leaveReview (): void {
    if (!this.authCheck() && this.drawerConnector) {
      return openAuthDrawer(this.drawerConnector)
    }

    this.modalConnector.open(Modals.AddReviewModal, {
      title: this.variant.attributes[AllowedAttributes.ProductLine],
      description: this.variant.name,
      variantHex: this.variant.identifier.value,
      variant: this.variant.identifier.type,
      sku: this.variant.sku
    })
  }

  public onUpdateVariant (sku: string) {
    this.setVariant(sku)
    this.checkIsFavoriteVariant()

    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        sku: sku
      }
    })
  }

  public setVariant (sku: string): void {
    const foundVariant = this.variants.find(({ sku: variantSku }) => variantSku === sku)
    if (!foundVariant) {
      return
    }

    this.currentVariant = foundVariant
  }

  public setVariantOnMount (): void {
    const sku = this.$route.query.sku
    if (!sku || Array.isArray(sku)) {
      return
    }

    this.setVariant(String(sku))
  }

  public async removeFromFavorite (): Promise<void> {
    if (!this.favouriteProductsService) {
      return
    }

    this.addToFavoriteBtnLoading = true

    try {
      await this.favouriteProductsService.removeFromFavourite(this.variant.sku)
      this.isFavorite = false
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.addToFavoriteBtnLoading = false
    }
  }

  protected detectedIfGift (sku: string): boolean {
    const keywords = ['gratis', 'free']

    const toTest: string = sku.toLowerCase()

    for (const k of keywords) {
      const shorten: string = toTest.replace(k, '')
      if (shorten.length < toTest.length) {
        return true
      }
    }

    return false
  }

  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.variant || typeof this.variant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.variant.attributes
      ? this.variant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, type: ToastType): void {
    this.showToast(message, type)
  }
}

export default ProductHeaderGratis
