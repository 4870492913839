













































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'

import { ComponentsStructureConfig, StructureConfigurable } from '../../../../support/mixins'

import { generateImagePlaceholder } from '../../../shared/support/image-placeholder'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { SizeMap } from '../../../../dsl/composables'

import { ArticleCardLayout, ArticleCardProps } from './ArticleCard.contracts'
import { ARTICLE_CARD_COMPONENT_KEY, DEFAULT_BUTTON_VARIANT } from './ArticleCard.config'

/**
 * Component capable to render `ArticleCard` element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ArticleCard>({
  name: 'ArticleCard',
  created (): void {
    this.config = this.getComponentConfig(ARTICLE_CARD_COMPONENT_KEY, {
      buttonVariant: 'outline'
    })
  }
})
export class ArticleCard extends Mixins(Vue, StructureConfigurable) {
  @Prop({ type: Array, required: false })
  public readonly categories?: ArticleCardProps['categories']

  @Prop({ type: String, required: true })
  public readonly content!: ArticleCardProps['content']

  @Prop({ type: Object, required: false })
  public readonly cover?: ArticleCardProps['cover']

  @Prop({ type: String, required: true })
  public readonly heading!: ArticleCardProps['heading']

  @Prop({ type: String, required: false, default: ArticleCardLayout.Vertical })
  public readonly layout?: ArticleCardProps['layout']

  @Prop({ type: String, required: true })
  public readonly link?: ArticleCardProps['link']

  @Prop({ type: String, required: true })
  public readonly publicationDate!: ArticleCardProps['publicationDate']

  /**
   * Determines component config.
   */
  public config: ComponentsStructureConfig = {}

  /**
   * Determines button variant.
   */
  public get buttonVariant (): string {
    const hasButtonVariantKey = this.config?.hasOwnProperty('buttonVariant')
    if (!this.config || typeof hasButtonVariantKey === 'undefined' || !this.config.buttonVariant) {
      return DEFAULT_BUTTON_VARIANT
    }

    return this.config.buttonVariant.toString()
  }

  /**
   * Determines whether categories are present.
   */
  public get hasCategories (): boolean {
    return typeof this.categories !== 'undefined' && this.categories.length > 0
  }

  /**
   * Determines whether article has `read-more` link.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' &&
      ((typeof this.link === 'string' && this.link.length > 0) || typeof this.link === 'object')
  }

  public get headingSize (): SizeMap {
    return this.isHorizontal ? SizeMap.Medium : SizeMap.XSmall
  }

  /**
   * Determines article image.
   */
  public get image (): ImageProps {
    if (!this.cover) {
      return generateImagePlaceholder('small')
    }

    return this.cover
  }

  public get isHorizontal (): boolean {
    return !!this.layout && this.layout === ArticleCardLayout.Horizontal
  }
}

export default ArticleCard
