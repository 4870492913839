
























import { BootstrapButton } from '@movecloser/ui-core'
import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'

import { TabsModuleContent } from '../../../Tabs.contracts'

/**
 * @emits update:activeContainerId - When the User clicks any of the tab buttons.
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<Tablist>({
  name: 'Tablist',
  components: { BootstrapButton },
  mounted (): void {
    this.enableTablistKeyboardControls()
  }
})
export class Tablist extends Vue {
  /**
   * @see TabsModuleContent.tabs
   */
  @Prop({ type: Array, required: true })
  public readonly tabs!: TabsModuleContent['tabs']

  /**
   * Determines whether component should behave as Anchor Links.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly withoutRole!: boolean

  /**
   * ID of the currently-active (visible) container.
   */
  @PropSync('activeContainerId', { required: true })
  public _activeContainerId!: string | null

  /**
   * Reference to the tablist element.
   */
  @Ref('tablist')
  public readonly tablist?: HTMLDivElement

  /**
   * Handles the `@click` event on the tab button.
   *
   * @param containerId - The ID of the container associated with the clicked tab.
   */
  public onBtnClick (containerId: string): void {
    this._activeContainerId = containerId
  }

  /**
   * Get aria selected value for given container.
   */
  public getAriaSelected (containerId: string): string {
    return this.isContainerActive(containerId) ? 'true' : 'false'
  }

  /**
   * Get role value.
   */
  public getRole (role: string): string | null {
    return this.withoutRole ? null : role
  }

  /**
   * Get tab index for given container.
   */
  public getTabIndex (containerId: string): string | null {
    if (this.withoutRole) {
      return null
    }

    return this.isContainerActive(containerId) ? '0' : '-1'
  }

  /**
   * Determines is active container.
   */
  public isContainerActive (containerId: string): boolean {
    return containerId === this._activeContainerId
  }

  /**
   * Enables switching between tabs wtth arrows in keyboard.
   */
  private enableTablistKeyboardControls (): void {
    if (typeof this.tablist === 'undefined') {
      return
    }

    const tabsBtns = this.tablist.querySelectorAll<HTMLButtonElement>('.Tablist__item__btn')

    // Enable arrow navigation between tabs
    let tabFocus: number = 0

    this.tablist.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.keyCode === 39 || e.keyCode === 37) {
        // Move right
        if (e.keyCode === 39) {
          tabFocus++

          // If we are at the end, jump to start.
          if (tabFocus >= tabsBtns.length) {
            tabFocus = 0
          }
        }

        // Move left
        if (e.keyCode === 37) {
          tabFocus--

          // If we are at the start, jumo to end
          if (tabFocus < 0) {
            tabFocus = tabsBtns.length - 1
          }
        }

        tabsBtns[tabFocus].focus()
      }
    })
  }
}

export default Tablist
